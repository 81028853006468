var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-container"},[_c('div',{staticClass:"share"},[_c('div',{staticClass:"title-container d-f a-c j-b"},[_c('div',{staticClass:"title"},[_vm._v("笔记下载")]),(_vm.shareNotes.length > 0)?_c('button',{on:{"click":_vm.downloadAll}},[_vm._v("全部下载")]):_vm._e()]),(_vm.shareNotes.length > 0)?_c('ul',{staticClass:"file-list d-f f-w"},_vm._l((_vm.shareNotes),function(item,index){return _c('li',{key:index},[(item.type === 'image')?_c('div',{staticClass:"top"},[_c('img',{staticClass:"img",attrs:{"src":item.url,"alt":item.filename}})]):(item.type === 'video')?_c('div',{staticClass:"top"},[_c('video',{staticClass:"img",attrs:{"src":item.url}})]):(item.type === 'ppt')?_c('div',{staticClass:"top"},[_vm._m(0,true)]):(item.type === 'doc')?_c('div',{staticClass:"top"},[_vm._m(1,true)]):(item.type === 'excel')?_c('div',{staticClass:"top"},[_vm._m(2,true)]):(item.type === 'pdf')?_c('div',{staticClass:"top"},[_vm._m(3,true)]):_c('div',{staticClass:"top"},[_vm._m(4,true)]),_c('div',{staticClass:"middle"},[_vm._v(_vm._s(item.filename))]),_c('div',{staticClass:"bottom d-f a-c j-c"},[_c('button',{on:{"click":function($event){return _vm.download(item)}}},[_vm._v("点击下载")])])])}),0):_c('div',{staticClass:"no-data-con"},[_vm._m(5),_c('div',{staticClass:"invalid"},[_vm._v("分享已经失效")])])]),(_vm.dust)?_c('div',{staticClass:"dust"},[_c('img',{staticClass:"guide",attrs:{"src":require("@/assets/img/share_guide.png")}}),_c('img',{staticClass:"know",attrs:{"src":require("@/assets/img/share_know.png")},on:{"click":_vm.iKnow}})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/img/share_ppt.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/img/share_doc.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/img/share_xls.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/img/share_pdf.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/img/share_unknown.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-data"},[_c('img',{attrs:{"src":require("@/assets/img/share_no_data.png"),"alt":"无数据"}})])
}]

export { render, staticRenderFns }