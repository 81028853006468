<!--
 * @Author: 庄志莹
 * @Date: 2021-03-09 16:04:13
 * @LastEditTime: 2021-06-30 16:01:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\record\general\map.vue
-->
<template>
  <div>
    <div class="container-map">
      <div class="map-top">
        <div class="map-top-con d-f a-c j-c">
          <img src="@/assets/img/map-title.png" alt="" />
        </div>
        <div class="back-general d-f a-c j-b" @click="backGeneral">
          <img src="@/assets/img/back-general.png" alt="" />
          <p>返回</p>
        </div>
        <div class="date d-f a-c j-b">
          <div class="date-string">{{ dateString }}</div>
          <div class="week-string">{{ weekString }}</div>
        </div>
        <div class="map-device d-f j-a a-c">
          <div class="num d-f a-c j-c">
            设备总数
            <div class="box" v-for="(item, index) in totalDeviceCountList" :key="index">
              {{ item }}
            </div>
            <span>台</span>
          </div>
          <div class="num d-f a-c j-c">
            设备在线台数
            <div class="box" v-for="(item, index) in onlineCountList" :key="index">
              {{ item }}
            </div>
            <span>台</span>
          </div>
          <div class="num d-f a-c j-c">
            服务师生总数
            <div class="box" v-for="(item, index) in totalUserList" :key="index">
              {{ item }}
            </div>
            <span>人</span>
          </div>
          <div class="num d-f a-c j-c">
            服务学校总数
            <div class="box" v-for="(item, index) in allSchoolCountList" :key="index">
              {{ item }}
            </div>
            <span>个</span>
          </div>
        </div>
      </div>
      <div ref="generalMap" class="general-map" id="generalMap"></div>
      <div class="map-middle">
        <div class="map-middle-title">省份终端数排名(台)</div>
        <div class="map-middle-main" ref="mapMiddleMain"></div>
      </div>
      <div class="map-middle-too">
        <div class="map-middle-too-title">学校终端数排名(台)</div>
        <div class="map-middle-too-main" ref="mapMiddleTooMain"></div>
      </div>
      <div class="map-bottom">
        <div class="map-bottom-title">各时间段终端在线数(台)</div>
        <div ref="mapBottomMain" class="map-bottom-main"></div>
      </div>
      <!-- <div class="icp d-f a-c j-c">
        ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号
        江苏小牛电子科技有限公司
      </div> -->
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import moment from "moment";
require("echarts/extension/bmap/bmap");
import { myStyleJson } from "./mapStyle.js";
// import { myStyleJson } from "./style";
import imgUrl from "@/assets/img/map-flag.png"; //引入自定义图标的图片，后面会用到
export default {
  data() {
    return {
      websocket: null,
      timer: null,
      obj: {},
      allSchoolCount: null,
      onlineCount: null,
      totalDeviceCount: null,
      totalUser: null,
      dateString: moment().format("YYYY-MM-DD"),
      weekString: moment().format("dddd"),
      onlineList: [],
      provinceList: [],
      deviceCountList: [],
      schoolList: [],
      schoolDeviceCountList: [],
    };
  },
  watch: {
    obj(val, oldVal) {
      //obj的数据有更新的时侯重绘myCharts1
      if (this.isChange(val, oldVal)) {
        this.obj = val;
        setTimeout(() => {
          this.myCharts();
        });
      }
    },
    onlineList(val, oldVal) {
      this.onlineList = val;
      setTimeout(() => {
        this.myCharts1();
      });
    },
    provinceList(val, oldVal) {
      this.provinceList = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    deviceCountList(val, oldVal) {
      this.deviceCountList = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    schoolList(val, oldVal) {
      this.schoolList = val;
      setTimeout(() => {
        this.myCharts3();
      });
    },
    schoolDeviceCountList(val) {
      this.schoolDeviceCountList = val;
      setTimeout(() => {
        this.myCharts3();
      });
    },
  },
  computed: {
    allSchoolCountList() {
      if (Object.keys(this.obj).length != 0) {
        let str = this.obj.allSchoolCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
    onlineCountList() {
      if (Object.keys(this.obj).length != 0) {
        let str = this.obj.onlineCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
    totalDeviceCountList() {
      if (Object.keys(this.obj).length != 0) {
        let str = this.obj.totalDeviceCount.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
    totalUserList() {
      if (Object.keys(this.obj).length != 0) {
        let str = this.obj.totalUser.toString();
        let arr = str.split("");
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    //返回数据概览
    backGeneral() {
      this.$router.go(-1);
    },
    isChange(obj1, obj2) {
      if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) {
        return true;
      } else if (
        obj1.allSchoolCount != obj2.allSchoolCount ||
        obj1.onlineCount != obj2.onlineCount ||
        obj1.totalDeviceCount != obj2.totalDeviceCount ||
        obj1.totalUser != obj2.totalUser
      ) {
        return true;
      } else {
        return false;
      }
    },
    //地图
    myCharts() {
      let myChart = echarts.init(this.$refs.generalMap); //这里是为了获得容器所在位置
      let res_data = this.obj.schoolList;
      let option = {
        bmap: {
          center: [110.114129, 23.550339],
          zoom: 5,
          roam: true,
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "#06AFFD",
          padding: [8, 20, 8, 12],
          extraCssText: "border-radius: 11px",
          formatter: function (val) {
            //返回tooltip的内容及布局样式
            return `
              <div style="font-size: 12px;color: #ffffff">学校：${val.data.schoolName}</div>
              <div style="font-size: 12px;color: #ffffff">设备数：${val.data.deviceCount}（${val.data.onlineCount}台在线）</div>
           `;
          },
        },
        series: [
          {
            name: "设备数量",
            type: "custom", //type的值为'custom'时，表示自定义图标
            coordinateSystem: "bmap",
            data: res_data,
            renderItem(params, api) {
              let latitude = res_data[params.dataIndex].latitude; //把坐标数据取出来 纬度
              let longitude = res_data[params.dataIndex].longitude; //精度
              let arr = [longitude, latitude];
              //具体实现自定义图标的方法
              return {
                type: "image",
                style: {
                  image: imgUrl, // 自定义的图片地址（上面引入，也可直接写图片的网络地址）
                  x: api.coord(arr)[0], // 图标的经度    必须使用 api.coord（）方法对坐标进行转化之后位置才正确
                  y: api.coord(arr)[1], //图标的纬度
                  width: 30, // 图标大小设置
                  height: 30,
                },
              };
            },
          },
        ],
      };
      myChart.setOption(option);
      var bmap = myChart.getModel().getComponent("bmap").getBMap();
      bmap.setMapStyle({ styleJson: myStyleJson });
    },
    //各个时间段在线数
    myCharts1() {
      let myChart = echarts.init(this.$refs.mapBottomMain);
      // 指定图表的配置项和数据
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
          data: [
            "0:00",
            "1:00",
            "2:00",
            "3:00",
            "4:00",
            "5:00",
            "6:00",
            "7:00",
            "8:00",
            "9:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "16%",
        },
        series: [
          {
            type: "line",
            smooth: 0.3,
            symbol: "none",
            lineStyle: {
              color: "#33CFDD",
              width: 3,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
            },
            areaStyle: {
              // color: "rgba(0, 175, 255, 0.3)",
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(5, 243, 234, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: " #0297BB", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: this.onlineList,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#00AFFF",
              type: "dashed",
            },
          },
          backgroundColor: "#33CFDD",
          padding: 10,
          formatter: (val) => {
            //返回tooltip的内容及布局样式
            // console.log(val);
            let obj = {};
            val.forEach((r) => {
              obj.time = r.name;
              obj.value = r.value;
            });
            return `
             <div>${this.dateString} ${obj.time}</div>
             <div style="margin-top:10px">设备在线数：${obj.value}</div>
           `;
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //省份终端排名
    myCharts2() {
      let myChart = echarts.init(this.$refs.mapMiddleMain);
      let option = {
        grid: {
          top: "0%",
          bottom: "-8%",
          left: "5%",
          right: "12%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
          type: "category",
          data: this.provinceList,
        },
        series: [
          {
            type: "bar",
            data: this.deviceCountList,
            barWidth: 10,
            z: 10,
            tooltip: { show: false },
            itemStyle: {
              // 柱子样式
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#2D8DEC", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(2, 226, 237, 0.2)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                barBorderRadius: [20],
                legendHoverLink: false,
                label: {
                  show: true, // 显示文本
                  position: ["103%", -1], // 数据值位置
                  formatter: "{c}台",
                  textStyle: {
                    color: "#ffffff",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //学校终端排名
    myCharts3() {
      let myChart = echarts.init(this.$refs.mapMiddleTooMain);
      let option = {
        grid: {
          top: "0%",
          bottom: "-8%",
          left: "5%",
          right: "12%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "#ffffff",
            },
          },
          type: "category",
          data: this.schoolList,
        },
        series: [
          {
            type: "bar",
            data: this.schoolDeviceCountList,
            barWidth: 10,
            z: 10,
            tooltip: { show: false },
            itemStyle: {
              // 柱子样式
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#2D8DEC", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(2, 226, 237, 0.2)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                barBorderRadius: [20],
                legendHoverLink: false,
                label: {
                  show: true, // 显示文本
                  position: ["103%", -1], // 数据值位置
                  formatter: "{c}台",
                  textStyle: {
                    color: "#ffffff",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = this.$globalVariable.wsurl; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      //连接建立之后执行send方法发送数据
      this.websocketsendFirst();
      this.websocketsendSecond();
      this.timer = setInterval(() => {
        this.doSend();
      }, 10 * 1000);
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend() {
      console.log("发送心跳");
      this.websocketsendSecond();
    },
    //首次发送数据 建立连接
    websocketsendFirst() {
      console.log("首次向后端发送数据");
      let userInfo = this.$ls.get("userInfo");
      let obj1 = {
        action: 1,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          receiverId: "",
          msg: "建立连接",
          msgType: 0,
        },
      };
      this.websocket.send(JSON.stringify(obj1)); //发送数据，传什么数据由实际需求决定
    },
    //获取数据总概数据信息
    websocketsendSecond() {
      console.log("获取数据总概数据信息");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "获取数据总概数据信息",
          msgType: 14,
        },
      };
      this.websocket.send(JSON.stringify(obj2));
    },
    //数据接收
    websocketonmessage(e) {
      console.log("接收后端返回数据");
      var result = JSON.parse(e.data);
      console.log(result);
      if (result.msgType === 14) {
        if (result.data) {
          this.obj = result.data;
        }
      }
    },
    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
    },
    //各时间段在线数
    getOnlineInfo() {
      this.$api.getOnlineInfo({ date: this.dateString }).then((res) => {
        let arr = [];
        for (let i = 0; i < 24; i++) {
          arr[i] = 0;
        }
        for (let i = 0; i < 24; i++) {
          res.data.onlineList.forEach((r) => {
            if (i === r.timeValue) {
              arr[i] = r.onlineCount;
            }
          });
        }
        this.onlineList = arr;
      });
    },
    //省份排名
    getDeviceOrder() {
      this.$api.getDeviceOrder().then((res) => {
        if (res.success) {
          let arr = res.data.list.filter((r) => r.province != "");
          if (arr.length > 8) {
            let arr1 = arr.slice(0, 8);
            this.provinceList = arr1.map((r) => r.province);
            this.deviceCountList = arr1.map((r) => r.deviceCount);
          } else {
            this.provinceList = arr.map((r) => r.province);
            this.deviceCountList = arr.map((r) => r.deviceCount);
          }
        }
      });
    },
    //学校排名
    getSchoolDeviceOrder() {
      this.$api.getSchoolDeviceOrder().then((res) => {
        // console.log(res);
        if (res.success) {
          this.schoolList = res.data.list.map((r) => r.schoolName);
          this.schoolDeviceCountList = res.data.list.map((r) => r.deviceCount);
        }
      });
    },
  },
  //vue生命周期创建后
  created() {
    this.initWebSocket();
    this.getOnlineInfo();
    this.getDeviceOrder();
    this.getSchoolDeviceOrder();
  },
  destroyed() {
    this.websocketclose();
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
  mounted() {
    setTimeout(() => {
      this.myCharts();
      this.myCharts1();
      this.myCharts2();
      this.myCharts3();
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize();
        }
      });
      resizeOb.observe(this.$refs.generalMap);
      resizeOb.observe(this.$refs.mapBottomMain);
      resizeOb.observe(this.$refs.mapMiddleMain);
      resizeOb.observe(this.$refs.mapMiddleTooMain);
    });
  },
};
</script>

<style lang="scss" scoped src="./map.scss"></style>
