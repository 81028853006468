<!--
 * @Author: 庄志莹
 * @Date: 2021-03-01 14:39:26
 * @LastEditTime: 2021-03-11 17:40:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\loginModule\wxlogin\wxlogin.vue
-->
<template>
  <div>
    <div class="container-login-success">
      <div class="title d-f j-c">
        <p>登录成功</p>
      </div>
      <div class="success d-f f-d a-c">
        <div class="success-box d-f j-c a-c">
          <img src="@/assets/img/bind-success.png" alt="" />
        </div>
        <span>请使用微信扫码登录或手机号登录</span>
      </div>
      <!-- <div class="icp d-f f-d a-c j-c">
        <div>©2021 XNIU 苏公网安备 32011502011289号</div>
        <div>苏ICP备2020065401号</div>
        <div>江苏小牛电子科技有限公司</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped src="./loginSuccess.scss"></style>
