<!--
 * @Author: your name
 * @Date: 2021-06-09 11:24:58
 * @LastEditTime: 2021-06-09 11:48:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\enter\index.vue
-->
<template>
  <div>
    <div class="enter-container">
      <div class="top">
        <img src="@/assets/img/xn_enter_bac.png" alt="" />
        <div class="title">欢迎来到智慧管理平台</div>
      </div>
      <div class="space">
        <div class="box-container">
          <router-link to="/main" tag="a">
            <div class="box">
              <img src="@/assets/img/xn_school_cloud.png" alt="" />
              <div class="content">智慧校园云平台</div>
            </div>
          </router-link>
          <a href="http://localhost:8081">
            <div class="box second">
              <img src="@/assets/img/xn_education_data.png" alt="" />
              <div class="content">教育资源网</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
}

.enter-container .top {
  width: 100%;
  position: relative;
}

.enter-container .top img {
  width: 100%;
}

.enter-container .top .title {
  position: absolute;
  top: 32%;
  left: 14%;
  font-size: 20px;
  font-family: "未来圆SC";
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}

.enter-container .space {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.enter-container .space .box {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 10px;
  padding: 30px 40px;
  text-align: center;
}
.enter-container .space .box:hover {
  box-shadow: 0px 0px 12px rgba(0, 124, 232, 0.1);
}

.enter-container .space .box:nth-child(2) {
  margin-top: 20px;
}

.enter-container .space .box img {
  width: 132px;
}

.enter-container .space .box .content {
  font-size: 20px;
  font-family: "Microsoft YaHei";
  font-weight: bold;
  color: #333333;
  opacity: 1;
  text-align: center;
}

@media (min-width: 1024px) {
  .enter-container .top .title {
    font-size: 38px;
  }

  .enter-container .space {
    margin-top: 80px;
  }

  .enter-container .space .box-container {
    display: flex;
  }

  .enter-container .space .box {
    padding: 50px 90px;
  }

  .enter-container .space .second {
    margin-left: 120px;
    margin-top: 0;
  }

  .enter-container .space .box img {
    width: 264px;
  }
}
</style>
