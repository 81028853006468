<!--
 * @Author: 庄志莹
 * @Date: 2020-12-26 16:18:07
 * @LastEditTime: 2021-05-24 16:33:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\user\user.vue
-->
<template>
  <div class="user-bg w">
    <div class="userHeader">
      <div class="userInfo-top">
        <img class="top-logo" src="@/assets/img/logo.png" alt="" />
        <p class="top-title">智慧校园云平台</p>
        <p class="top-msg">个人中心</p>
        <div class="top-rBox">
          <img
            class="avatar"
            src="@/assets/img/headportrait.png"
            v-if="avatar === null || avatar === ''"
          />
          <img class="avatar" :src="avatar" v-else />
          <span class="userName">{{ username }}</span>
        </div>
        <div @click="goMain" class="return">返回</div>
      </div>
    </div>
    <a-layout class="userContainer w">
      <!-- 用户侧边栏 -->
      <a-layout-sider class="userMenu" style="width: 228px; border-radius: 10px 0 0 10px">
        <a-menu
          style="
            width: 228px;
            height: 712px;
            padding-top: 24px;
            border-radius: 10px 0 0 10px;
          "
          :default-selected-keys="[this.$route.path]"
          :selectedKeys="[this.$route.path]"
          mode="inline"
        >
          <a-menu-item key="/user/setup">
            <router-link to="/user/setup" replace>基本设置</router-link>
          </a-menu-item>
          <a-menu-item key="/user/set-password">
            <router-link to="/user/set-password" replace>修改密码</router-link>
          </a-menu-item>
          <a-menu-item key="/user/new-message">
            <router-link to="/user/new-message" replace>消息通知</router-link>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <!-- 用户主体部分 -->
      <a-layout>
        <a-layout-content class="userContainerR">
          <router-view @getAvatarChange="avatarChange" />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- <div class="icp d-f a-c j-c">
      ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号 江苏小牛电子科技有限公司
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      key1: 1,
      activeItem: {
        setup: 1,
        setPassword2: 2,
        cloudDisk: 3,
        NewMessage: 4,
      },
      username: "",
      avatar: null,
    };
  },
  watch: {},
  methods: {
    goMain() {
      this.$router.go(-1);
    },
    avatarChange(val) {
      this.avatar = val;
    },
  },
  created() {
    let userInfo = this.$ls.get("userInfo");
    this.username = userInfo.username;
    this.avatar = userInfo.avatar;
  },
};
</script>

<style lang="scss" scope>
@import "./user.scss";
</style>
