<!--
 * @Author: 庄志莹
 * @Date: 2021-03-01 14:39:26
 * @LastEditTime: 2021-05-12 19:23:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\loginModule\wxlogin\wxlogin.vue
-->
<template>
  <div>
    <div class="container-bind-wechat" v-if="bindWechat">
      <div class="title d-f j-c">
        <p>绑定手机号</p>
      </div>
      <div class="logo d-f f-d a-c">
        <div class="logo-box d-f j-c a-c">
          <img src="@/assets/img/logo.png" alt="" />
        </div>
        <p>智慧校园云平台</p>
      </div>
      <div class="ipt">
        <a-input placeholder="请输入手机号" class="phone" v-model="phone" />
        <!-- <a-input placeholder="请输入验证码" class="code" v-model="code" /> -->
        <!-- <div class="send-code">
          <a-divider type="vertical" />
          <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
          <a-button v-if="!isSend" disabled>{{ count }}s</a-button>
        </div> -->
        <div class="tooltip d-f a-c j-c" v-if="visible">手机号格式错误~</div>
        <div class="tooltip d-f a-c j-c" v-if="visibleToo">该手机号还没被注册过~</div>
        <div class="tooltip d-f a-c j-c" v-if="visibleThird">请勾选复选框~</div>
        <div class="tooltip d-f a-c j-c" v-if="visibleFifth">验证码格式错误~</div>
      </div>
      <div class="checkbox">
        <a-checkbox :checked="checkAgree" @change="handleChange"> 同意 </a-checkbox>
        <a class="login-service" href="https://cloud.jsxniu.com/service" target="_blank"
          >《服务协议》</a
        >
        <a class="login-policy" href="https://cloud.jsxniu.com/privacy" target="_blank"
          >《隐私政策》</a
        >
      </div>
      <a-button type="primary" class="login-submit" @click="bindAndLogin">
        绑定并登录
      </a-button>
      <!-- <div class="icp d-f f-d a-c j-c">
        <div>©2021 XNIU 苏公网安备 32011502011289号</div>
        <div>苏ICP备2020065401号</div>
        <div>江苏小牛电子科技有限公司</div>
      </div> -->
    </div>
    <div class="container-bind-success" v-if="bindSuccess">
      <div class="title d-f j-c">
        <p>绑定成功</p>
      </div>
      <div class="success d-f f-d a-c">
        <div class="success-box d-f j-c a-c">
          <img src="@/assets/img/bind-success.png" alt="" />
        </div>
        <p>恭喜您微信和手机号绑定成功</p>
        <span>请使用微信扫码登录或手机号登录</span>
      </div>
      <!-- <div class="icp d-f f-d a-c j-c">
        <div>©2021 XNIU 苏公网安备 32011502011289号</div>
        <div>苏ICP备2020065401号</div>
        <div>江苏小牛电子科技有限公司</div>
      </div> -->
    </div>
    <div class="container-bind-success" v-if="bindFailure">
      <div class="title d-f j-c">
        <p>绑定失败</p>
      </div>
      <div class="success d-f f-d a-c">
        <div class="success-box d-f j-c a-c">
          <img src="@/assets/img/bind-failure.png" alt="" />
        </div>
        <p>您微信和手机号绑定失败~</p>
        <span>请使用微信扫码登录或手机号登录</span>
      </div>
      <!-- <div class="icp d-f f-d a-c j-c">
        <div>©2021 XNIU 苏公网安备 32011502011289号</div>
        <div>苏ICP备2020065401号</div>
        <div>江苏小牛电子科技有限公司</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: null,
      code: null,
      uuid: "",
      checkAgree: true,
      count: 60,
      isSend: true,
      timer: null,
      visible: false,
      visibleToo: false,
      visibleThird: false,
      visibleFifth: false,
      bindWechat: true,
      bindSuccess: false,
      bindFailure: false,
    };
  },
  methods: {
    //复选框状态改变
    handleChange(e) {
      this.checkAgree = e.target.checked;
    },
    //发送验证码
    sendVerifyCode() {
      if (this.validatePhone(this.phone)) {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.isSend = false;
          //验证手机号已经注册过
          this.$api.isExist({ phone: this.phone }).then((res) => {
            // console.log(res)
            if (res.success) {
              //发送短信验证码
              this.$api
                .sendSmsCode({
                  phone: this.phone,
                  smsCodeType: 6,
                })
                .then((res) => {
                  // console.log(res)
                  this.$message.info(res.message);
                  return;
                });
            } else {
              this.visibleToo = true;
              this.phone = null;
              setTimeout(() => {
                this.visibleToo = false;
              }, 3000);
            }
          });
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.isSend = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    validatePhone(value) {
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!phone.test(value)) {
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, 3000);
        return false;
      } else {
        return true;
      }
    },
    validateCode(value) {
      let code = /^[0-9]{4}$/;
      if (!code.test(value)) {
        this.visibleFifth = true;
        setTimeout(() => {
          this.visibleFifth = false;
        }, 3000);
        return false;
      } else {
        return true;
      }
    },
    getUuid(url) {
      let num = url.indexOf("?");
      let str = url.substr(num + 1);
      let arr = str.split("&");
      let kv = [];
      arr.forEach((r) => {
        let n = r.indexOf("=");
        let k = r.substring(0, n);
        let v = r.substr(n + 1);
        let obj = {};
        obj[k] = v;
        kv.push(obj);
      });
      return kv;
    },
    //绑定并登录
    bindAndLogin() {
      if (this.validatePhone(this.phone) ) {
        if (this.checkAgree) {
          this.$api
            .bindAndLoginToo({
              phone: this.phone,
              uuid: this.uuid,
            })
            .then((res) => {
              if (res.success) {
                this.bindWechat = false;
                this.bindSuccess = true;
              } else {
                this.bindWechat = false;
                this.bindFailure = true;
              }
            });
        } else {
          this.visibleThird = true;
          setTimeout(() => {
            this.visibleThird = false;
          }, 2000);
        }
      }
    },
  },
  created() {
    let url = location.href;
    let arr = this.getUuid(url);
    let obj = arr.find((r) => r.uuid);
    if (obj != undefined) {
      this.uuid = obj.uuid;
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scoped src="./bindWechat.scss"></style>
