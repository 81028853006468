var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.bindWechat)?_c('div',{staticClass:"container-bind-wechat"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"ipt"},[_c('a-input',{staticClass:"phone",attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(_vm.visible)?_c('div',{staticClass:"tooltip d-f a-c j-c"},[_vm._v("手机号格式错误~")]):_vm._e(),(_vm.visibleToo)?_c('div',{staticClass:"tooltip d-f a-c j-c"},[_vm._v("该手机号还没被注册过~")]):_vm._e(),(_vm.visibleThird)?_c('div',{staticClass:"tooltip d-f a-c j-c"},[_vm._v("请勾选复选框~")]):_vm._e(),(_vm.visibleFifth)?_c('div',{staticClass:"tooltip d-f a-c j-c"},[_vm._v("验证码格式错误~")]):_vm._e()],1),_c('div',{staticClass:"checkbox"},[_c('a-checkbox',{attrs:{"checked":_vm.checkAgree},on:{"change":_vm.handleChange}},[_vm._v(" 同意 ")]),_c('a',{staticClass:"login-service",attrs:{"href":"https://cloud.jsxniu.com/service","target":"_blank"}},[_vm._v("《服务协议》")]),_c('a',{staticClass:"login-policy",attrs:{"href":"https://cloud.jsxniu.com/privacy","target":"_blank"}},[_vm._v("《隐私政策》")])],1),_c('a-button',{staticClass:"login-submit",attrs:{"type":"primary"},on:{"click":_vm.bindAndLogin}},[_vm._v(" 绑定并登录 ")])],1):_vm._e(),(_vm.bindSuccess)?_c('div',{staticClass:"container-bind-success"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.bindFailure)?_c('div',{staticClass:"container-bind-success"},[_vm._m(4),_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title d-f j-c"},[_c('p',[_vm._v("绑定手机号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo d-f f-d a-c"},[_c('div',{staticClass:"logo-box d-f j-c a-c"},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":""}})]),_c('p',[_vm._v("智慧校园云平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title d-f j-c"},[_c('p',[_vm._v("绑定成功")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"success d-f f-d a-c"},[_c('div',{staticClass:"success-box d-f j-c a-c"},[_c('img',{attrs:{"src":require("@/assets/img/bind-success.png"),"alt":""}})]),_c('p',[_vm._v("恭喜您微信和手机号绑定成功")]),_c('span',[_vm._v("请使用微信扫码登录或手机号登录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title d-f j-c"},[_c('p',[_vm._v("绑定失败")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"success d-f f-d a-c"},[_c('div',{staticClass:"success-box d-f j-c a-c"},[_c('img',{attrs:{"src":require("@/assets/img/bind-failure.png"),"alt":""}})]),_c('p',[_vm._v("您微信和手机号绑定失败~")]),_c('span',[_vm._v("请使用微信扫码登录或手机号登录")])])
}]

export { render, staticRenderFns }