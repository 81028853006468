<!--
 * @Author: 庄志莹
 * @Date: 2021-04-10 15:31:44
 * @LastEditTime: 2021-04-12 08:46:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\other\PrivacyPolicy.vue
-->
<template>
  <div class="privacy-container">
    <div class="main">
      <p class="center">智慧校园云平台隐私政策</p>
      <p>引言</p>
      <p>
        隐私信息是指公民个人生活中不愿为他人公开或知悉的秘密。智慧校园云平台重视用户隐私信息的保护，您在使用智慧校园云平台产品和服务时，智慧校园云平台可能会收集和使用您的相关信息。智慧校园云平台希望通过本政策向您说明智慧校园云平台可能会收集的信息（包括收集方式）、收集这些信息的用途、智慧校园云平台及您如何保护这些信息的安全等。请您在使用智慧校园云平台所有产品及服务前，仔细阅读本政策，并确定了解我们对您个人信息的处理规则。如您对本隐私协议有任何疑问，可联系我们进行咨询，如您不同意本隐私协议中的任何条款，您应立即停止使用智慧校园云平台产品和服务。一旦您选择使用或在智慧校园云平台更新本隐私政策后（智慧校园云平台会及时提示您更新的情况）继续使用智慧校园云平台产品和服务，即表示您认可并接受本政策（含更新版本）的所有内容，并同意智慧校园云平台按本政策收集、使用、保存和共享您的相关信息。
      </p>
      <p>一、我们可能收集的信息</p>
      <p>二、我们收集信息的用途及使用规则</p>
      <p>三、信息的共享、转让及披露</p>
      <p>四、我们的信息安全保护</p>
      <p>五、您对个人信息的管理</p>
      <p>六、未成年人信息保护</p>
      <p>七、免责条款</p>
      <p>八、本政策的适用范围</p>
      <p>九、本政策的修订及生效日期</p>
      <br />
      <p>一、我们可能收集的信息</p>
      <p>
        智慧校园云平台收集信息是为了向所有用户提供更好的服务。智慧校园云平台收集信息的主要来源有：您向智慧校园云平台提供的信息、智慧校园云平台在您使用智慧校园云平台产品和服务过程中获取的信息、第三方分享的您的信息。智慧校园云平台所收集的信息类型主要包括以下几种：
      </p>
      <p>1.个人信息</p>
      <p>
        个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。
      </p>
      <p>
        您在注册账户或使用智慧校园云平台产品和服务时，经您的同意和确认后智慧校园云平台可能会收集您所提供的相关个人信息，例如姓名、性别、年龄、出生日期、证件号码、银行卡号、电子邮件地址、电话号码、通讯地址、职业、教育程度等。
      </p>
      <p>2.设备信息</p>
      <p>设备信息，是指您使用智慧校园云平台产品或服务时使用的设备的信息。</p>
      <p>
        您在使用智慧校园云平台产品或服务时，智慧校园云平台会收集您使用智慧校园云平台产品或服务的设备相关信息，例如设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（pLMN）等。这些信息能帮助智慧校园云平台为您提供更优的信息展示或产品服务。
      </p>
      <p>3.位置信息</p>
      <p>
        位置信息，是指您开启设备定位功能并使用智慧校园云平台基于位置提供的相关服务时，收集的有关您位置的信息，主要包括：
      </p>
      <p>
        （1）当您开启设备定位功能并使用智慧校园云平台相关产品或服务时，智慧校园云平台可能会使用GpS、WiFi或其他技术方式收集和处理有关您实际所在位置的信息。
      </p>
      <p>
        （2）您或其他用户提供的包含您所处地理位置的实时信息。例如您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息。
      </p>
      <p>
        （3）如您不想被访问，可以选择关闭设备或产品/服务中的相关功能，但可能会因此影响智慧校园云平台向您提供相关服务。
      </p>
      <p>4.日志信息</p>
      <p>
        指您使用智慧校园云平台产品和服务时，系统可能通过cookies或其他方式自动收集某些信息并存储在服务器日志中。此类信息可能包括：
      </p>
      <p>
        （1）对智慧校园云平台产品和服务的详细使用情况，例如您使用的网页搜索词语、访问的页面地址、以及您在使用智慧校园云平台服务时浏览或要求提供的其他信息和内容详情；用户习惯统计数据，例如在使用智慧校园云平台输入法时的设置项信息、部分按键的使用次数、上屏字词的次数等，该类数据都是匿名的，智慧校园云平台无法根据上传数据定位到具体用户。
      </p>
      <p>
        （2）设备或软件信息，例如您的移动设备、网页浏览器或用于接入智慧校园云平台产品或服务的其他程序所提供的配置信息、移动设备所用的版本和设备识别码等。
      </p>
      <p>（3）设备事件信息，例如崩溃、系统活动等信息。</p>
      <p>（4）Ip地址。</p>
      <p>5.经您同意后收集的其他信息。</p>
      <br />
      <p>二、智慧校园云平台收集信息的用途及使用规则</p>
      <p>（一）我们可能将所收集的信息用作下列用途：</p>
      <p>
        1.在智慧校园云平台提供产品和服务时，用于身份验证、客户服务、安全防范、存档和备份等用途，确保智慧校园云平台向您提供的产品和服务的安全性。
      </p>
      <p>
        2.保证为您所提供的产品或服务功能的正常实现。例如：为了实现对您的个性化推荐功能，了解您如何接入和使用智慧校园云平台的产品和服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应等。
      </p>
      <p>3.改善智慧校园云平台现有产品和服务，提升用户体验。例如</p>
      <p>
        （1）智慧校园云平台可能会收集您的浏览信息、您的兴趣爱好（您可以在账户设置中选择）进行数据分析，用来将您感兴趣服务信息展示给您；或在您搜索时向您展示您可能希望找到的信息内容。
      </p>
      <p>
        （2）智慧校园云平台还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向智慧校园云平台发送的问卷答复信息，以及您与智慧校园云平台互动时智慧校园云平台获得的相关信息。
      </p>
      <p>
        （3）对于从您的各种设备上收集到的信息，智慧校园云平台可能会将它们进行关联，以便能在这些设备上为您提供一致的服务。
      </p>
      <p>
        （4）智慧校园云平台可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。
      </p>
      <p>
        4.帮助智慧校园云平台设计新产品和服务。例如智慧校园云平台在提供某些产品或服务中，可能会请用户参与“用户体验改进计划”，加入用户体验改进计划的用户，智慧校园云平台会根据需要对产品和服务的各项功能使用情况进行统计，这样可以通过分析统计数据提高产品和服务质量，推出对用户有帮助的创新产品和服务。如您不想参与该计划，可以选择关闭相关产品/服务中的相关功能。
      </p>
      <p>
        5.智慧校园云平台可能使用您的相关信息，向您提供与您更加相关的广告或推广信息。智慧校园云平台也可能使用您的信息，通过智慧校园云平台的服务、电子邮件或其他方式向您发送营销信息，提供或推广智慧校园云平台或第三方的商品和服务。如您不希望智慧校园云平台将您的个人信息用作前述用途，您可以通过智慧校园云平台相关产品或服务中提供的相关渠道，要求智慧校园云平台停止为上述用途使用您的个人信息。
      </p>
      <p>6.软件认证或管理软件升级。</p>
      <p>7.根据法律法规在如下情形收集使用您的相关信息，此种情况下无须获得您的授权：</p>
      <p>（1）与国家安全、国防安全有关的；</p>
      <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p>
        （4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p>
        （5）用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
      </p>
      <p>（6）为合法的新闻报道所必需的；</p>
      <p>
        （7）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      </p>
      <p>（8）法律法规规定的其他情形。</p>
      <p>8.经您同意的其他用途。</p>
      <p>
        为了让您有更好的体验、改善智慧校园云平台的产品和服务或为了您同意的其他用途，在符合相关法律法规的前提下，智慧校园云平台可能将通过某一产品或服务所收集的信息，以汇集信息或者个性化的方式，用于智慧校园云平台的其他产品和服务。例如，在您使用智慧校园云平台的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果智慧校园云平台在相关服务中提供了相应选项，您也可以授权智慧校园云平台将该服务所提供和储存的信息用于智慧校园云平台的其他服务。
      </p>
      <p>（二）对您个人信息使用的规则</p>
      <p>
        1.智慧校园云平台会根据本隐私政策的约定并为实现智慧校园云平台的产品与/或服务功能对所收集的信息进行使用。
      </p>
      <p>
        2.在收集您的个人信息后，智慧校园云平台将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下智慧校园云平台有权使用已经去标识化的信息；并在不透露您个人信息的前提下，有权对用户数据库进行分析并予以商业化的利用。
      </p>
      <p>
        3.请您注意，您在使用智慧校园云平台的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝智慧校园云平台收集，否则将在您使用智慧校园云平台的产品与/或服务期间持续授权智慧校园云平台使用。在您注销账号时，智慧校园云平台将停止使用并删除您的个人信息。
      </p>
      <p>
        4.智慧校园云平台会对智慧校园云平台的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示智慧校园云平台的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
      </p>
      <p>
        5.当智慧校园云平台展示您的个人信息时，智慧校园云平台会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
      </p>
      <p>
        6.当智慧校园云平台要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。
      </p>
      <br />
      <p>三、信息的共享、转让及披露</p>
      <p>（一）信息的共享</p>
      <p>
        1.智慧校园云平台不会与智慧校园云平台以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
      </p>
      <p>（1）事先获得您明确的同意或授权；</p>
      <p>
        （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
      </p>
      <p>
        （3）在法律法规允许的范围内，为维护智慧校园云平台或合作伙伴、您或其他智慧校园云平台用户或社会公众利益、财产或安全免遭损害而有必要提供；
      </p>
      <p>
        （4）只有共享您的信息，才能实现智慧校园云平台的产品与/或服务的核心功能或提供您需要的服务；
      </p>
      <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
      <p>
        （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      </p>
      <p>（7）基于学术研究而使用；</p>
      <p>（8）基于符合法律法规的社会公共利益而使用。</p>
      <p>
        2.智慧校园云平台可能会将您的个人信息与智慧校园云平台的关联方共享。但智慧校园云平台只会共享必要的个人信息，且受本隐私政策的约束。智慧校园云平台的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
      </p>
      <p>
        3.智慧校园云平台可能会向合作伙伴等第三方共享您的信息，以保障为您提供的服务顺利完成。但智慧校园云平台仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。智慧校园云平台的合作伙伴无权将共享的个人信息用于任何其他用途。
      </p>
      <p>
        4.对智慧校园云平台与之共享个人信息的公司、组织和个人，智慧校园云平台会要求遵守其遵守保密约定，要求他们按照智慧校园云平台的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p>
        5.为了遵守法律、执行或适用智慧校园云平台的使用条件和其他协议，或者为了保护智慧校园云平台、您或其他智慧校园云平台客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过，这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
      </p>
      <p>（二）信息的转让</p>
      <p>智慧校园云平台不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>（1）事先获得您明确的同意或授权；</p>
      <p>
        （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
      </p>
      <p>
        （3）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      </p>
      <p>
        （4）在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，智慧校园云平台会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,智慧校园云平台将要求该公司、组织重新向您征求授权同意。
      </p>
      <p>（三）信息的披露</p>
      <p>
        智慧校园云平台仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
      </p>
      <p>（1）您同意或授权智慧校园云平台披露的；</p>
      <p>（2）您使用共享功能的；</p>
      <p>
        （3）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，智慧校园云平台可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当智慧校园云平台收到上述披露信息的请求时，智慧校园云平台会要求必须出具与之相应的法律文件智慧校园云平台对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；
      </p>
      <p>
        （4）在紧急情况下，为了保护您、智慧校园云平台及其他用户的合法权益或公共安全及利益的；
      </p>
      <p>（5）符合智慧校园云平台相关产品和服务的用户协议或其他类似协议的规定。</p>
      <br />
      <p>四、我们的信息安全保护</p>
      <p>
        1.智慧校园云平台仅在本政策所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
      </p>
      <p>
        2.数据安全技术措施：智慧校园云平台会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改、避免数据的损坏或丢失；智慧校园云平台将使用安全技术和程序监测、记录网络运行状态、网络安全事件，并采取必要的技术措施保障网络安全，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，智慧校园云平台将利用加密技术来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入智慧校园云平台的产品和服务所用的系统和通讯网络，有可能因智慧校园云平台可控范围外的因素而出现问题。
      </p>
      <p>
        3.智慧校园云平台仅允许有必要知晓这些信息的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。智慧校园云平台同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止智慧校园云平台的合作关系。
      </p>
      <p>
        4.智慧校园云平台会采取一切合理可行的措施，确保无关的个人信息不被收集。智慧校园云平台只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
      </p>
      <p>
        5.您在使用智慧校园云平台的产品和服务时所提供、上传或发布的内容和信息（例如有关您个人活动的照片等信息），可能会泄露您的个人信息。您需要谨慎地考虑，是否在使用智慧校园云平台的产品和服务时披露相关个人信息。在一些情况下，您可通过智慧校园云平台某些产品和服务的隐私设定来控制有权浏览您共享信息的用户范围。
      </p>
      <p>
        6.互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，智慧校园云平台建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
      </p>
      <p>
        7.安全事件处理：为应对个人信息泄露、损毁和丢失等可能出现的风险，智慧校园云平台建立了专门的应急响应团队，按照公司有关安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、制定补救措施、联合相关部门进行溯源和打击。同时智慧校园云平台将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、智慧校园云平台已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。智慧校园云平台同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，智慧校园云平台会采取合理、有效的方式发布公告。同时，智慧校园云平台还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <br />
      <p>五、您对个人信息的管理</p>
      <p>
        1.您有权访问、更正、删除您的个人信息；但相关信息的删除或修改可能会影响您对相关产品或服务的使用或导致部分功能的实现。
      </p>
      <p>
        2.您有权改变您授权同意的范围或撤回您的授权；您可以通过删除信息、关闭设备功能、在网站或软件中进行隐私设置等方式改变您授权智慧校园云平台继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回智慧校园云平台继续收集您个人信息的全部授权。请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，智慧校园云平台无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </p>
      <p>
        3.您也可以在满足相关产品约定条件的情况下，直接申请注销相关产品账户。您注销账户后，智慧校园云平台将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，智慧校园云平台将删除您的个人信息。
      </p>
      <p>
        4.智慧校园云平台将采取适当的技术手段，保证您对于自己的个人资料可进行查询、补充、更正或删除，或通过智慧校园云平台相关产品或服务发布的反馈或投诉渠道申请智慧校园云平台对相关信息进行补充、更正或删除；智慧校园云平台收到您的申请后将按流程（如智慧校园云平台可能会要求您提供相关证明，以确认您的身份）予以处理。
      </p>
      <p>
        5.如果您无法访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用智慧校园云平台产品与/或服务时所产生的其他个人信息，或您认为智慧校园云平台存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您可通过智慧校园云平台对外公布的相关产品或服务的官方反馈渠道或与智慧校园云平台联系。
      </p>
      <br />
      <p>六、未成年人信息保护</p>
      <p>
        1.
        智慧校园云平台非常重视未成年人个人信息的保护。如果您是18周岁以下的未成年人，在提交个人信息前，应事先得到家长或其法定监护人（以下统称为“监护人”）的同意。
      </p>
      <p>
        2.
        智慧校园云平台鼓励监护人指导未成年人使用智慧校园云平台的产品和服务，并建议未成年人鼓励其监护人阅读本政策。
      </p>
      <p>3. 监护人应承担保护未成年人在网络环境下的隐私权的首要责任。</p>
      <p>4. 智慧校园云平台将根据国家相关法律法规保护未成年人的相关信息。</p>
      <br />
      <p>七、免责条款</p>
      <p>除本政策已列明的免责情况外，下列情况智慧校园云平台也无须承担任何责任：</p>
      <p>
        1.
        由于您将用户密码告知他人、与他人共享注册账户或其他因您个人保管不善，由此导致的任何个人资料泄露、丢失、被盗用或被篡改等。
      </p>
      <p>
        2.
        任何由于黑客政击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等。
      </p>
      <p>
        3.
        由于与智慧校园云平台链接的其他网站所造成的个人信息的泄露及由此产生的任何法律争议和后果。
      </p>
      <p>
        4. 其他非智慧校园云平台原因导致的个人信息的泄露及由此产生的任何法律争议和后果。
      </p>
      <br />
      <p>八、本政策的适用范围</p>
      <p>
        1.
        智慧校园云平台所有的产品和服务均适用本政策。针对某些特定产品和服务的特定隐私政策，将在相关产品和服务的使用协议或单独的隐私协议中特别具体说明。该等特定产品和服务的隐私条款构成本政策的一部分。如相关特定产品和服务的隐私条款与本政策有不一致之处，适用该特定产品和服务的隐私条款。
      </p>
      <p>
        2.
        本政策仅适用于智慧校园云平台所收集的信息，并不适用于任何第三方提供的产品和服务或第三方的信息使用规则，例如通过智慧校园云平台的产品和服务而接受的第三方产品和服务（包括任何第三方网站）收集的信息，不适用本政策。智慧校园云平台对任何第三方使用由您提供的信息不承担任何责任。
      </p>
      <p>
        3.
        如您在使用智慧校园云平台产品、服务中对本隐私政策有任何问题，您可通过智慧校园云平台对外公布的相关产品或服务的官方反馈渠道或通过邮箱xiaoniu@jsxniu.com与智慧校园云平台联系。
      </p>
      <p>
        4.
        本政策中“关联公司”指现在或将来与江苏电子科技有限公司构成“一方有能力直接或间接控制、共同控制另一方或对另一方施加重大影响，以及两方或多方以上同受一方控制、共同控制或重大影响的关系的企业”。
      </p>
      <br />
      <p>九、本政策的修订及生效日期</p>
      <p>
        智慧校园云平台可能适时修订本政策的条款，该等修订构成本政策的一部分。修订后的政策智慧校园云平台将及时予以发布。若您继续使用智慧校园云平台产品和服务，即表示您同意受经修订的隐私政策的约束。
      </p>
      <p>本政策于2021年4月10日发布，自2021年4月11日起生效。</p>
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.privacy-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .main {
    width: 100%;
    height: auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .main > p {
    /* margin: 0; */
    font-size: 14px;
    /* letter-spacing: 1px; */
    line-height: 20px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    color: #333;
    margin: 0;
  }
  .main > p.center {
    text-align: center;
    font-weight: bold;
    margin-top: 24px;
  }
  @media screen and (min-width: 768px) {
    .main {
      width: 70%;
      margin: 0 auto;
      margin-top: 20px;
    }
    .main > p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
