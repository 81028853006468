<!--
 * @Descripttion: 注册页面
 * @version: v1.0.0
 * @Author: zhangqinggang
 * @Date: 2020-11-17 09:20:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-09 11:31:18
-->

<template>
  <div class="loginContainer">
    <div class="loginBox">
      <div class="loginBoxL">
        <div class="logo"><img src="@/assets/img/logo.png" alt="" /></div>
        <span class="texOne">智慧校园云平台</span>
        <span class="texTwo">优秀的统一集控管理平台</span>
      </div>
      <div class="loginBoxR">
        <div class="loginBoxTab">
          <button
            class="registerTab"
            :class="{ loginTabHover: loginActive }"
            @click="showLogin()"
          >
            1.注册账户
          </button>
          <button class="setPasswordTab" :class="{ sginTabHover: sginActive }">
            2.设置密码
          </button>
        </div>
        <!-- 注册用户-1 -->
        <div class="loginFrom" v-show="showLoginOne" style="display: none">
          <RegisterUser />
        </div>
        <!-- 注册用户-1 结束 -->
        <!-- 注册设置密码-2 -->
        <div class="loginFrom" v-show="showLoginTwo">
          <RegisterPassword />
        </div>
      </div>
      <!-- 注册设置密码-2 -->
    </div>

    <!-- 底部信息 -->
    <!-- <span class="layer"
      >©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号
      江苏小牛电子科技有限公司</span
    > -->
    <!-- 底部信息 -->
  </div>
</template>

<script>
import RegisterUser from "./register-item/register-user";
import RegisterPassword from "./register-item/register-password";

export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePassT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次密码不一样"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        pass: "",
        checkPass: "",
        checked: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "change" }],
        checkPass: [{ validator: validatePassT, trigger: "change" }],
      },
      showLoginOne: "true",
      showLoginTwo: "",
      loginActive: "ture",
      sginActive: "",
    };
  },
  components: {
    RegisterUser,
    RegisterPassword,
  },
  methods: {
    /**
     * @Author: zhangqinggang
     * @description: 切换显示
     * @param {*}
     * @param2: {params2}
     * @return {*}
     */
    showLogin() {
      this.showLoginOne = true;
      this.showLoginTwo = false;
      this.loginActive = true;
      this.sginActive = "";
    },
    showFromSgin() {
      this.showLoginOne = false;
      this.showLoginTwo = true;
      this.sginActive = true;
      this.loginActive = "";
    },
    /**
     * @Author: zhangqinggang
     * @description: 登录跳转
     * @param {*}
     * @param2: {params2}
     * @return {*}
     */
    goHome() {
      this.$router.push("home");
    },
    goRegister() {
      this.$router.push("register");
    },
    goLogin() {
      this.$router.push("/login");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scope>
@import "./register.scss";
</style>
