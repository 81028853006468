<!--
 * @Descripttion: 忘记密码页面
 * @version: v1.0.0
 * @Author: zhangqinggang
 * @Date: 2020-11-17 09:20:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-09 11:31:11
-->

<template>
  <div class="loginContainer">
    <div class="loginBox">
      <div class="loginBoxL">
        <div class="logo"><img src="@/assets/img/logo.png" alt="" /></div>
        <span class="texOne">智慧校园云平台</span>
        <span class="texTwo">优秀的统一集控管理平台</span>
      </div>
      <div class="loginBoxR">
        <div class="loginBoxTab">
          <button
            class="loginTab"
            :class="{ loginTabHover: loginActive }"
            @click="showLogin()"
          >
            1.确认账号
          </button>
          <button class="sginTab" :class="{ sginTabHover: sginActive }">
            2.重置密码
          </button>
        </div>
        <!-- 重置密码-1 -->
        <div class="loginFrom" v-show="showLoginOne" style="display: none">
          <ForgetPassword />
        </div>

        <!-- 重置密码-1 结束 -->
        <!-- 确认账号-2 -->
        <div class="loginFrom" v-show="showLoginTwo">
          <ForgetUser />
        </div>
      </div>
      <!-- 确认账号-2 -->
    </div>

    <!-- 底部信息 -->
    <!-- <span class="layer"
      >©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号 江苏小牛电子科技有限公司
    </span> -->
    <!-- 底部信息 -->
  </div>
</template>

<script>
import ForgetUser from "./forget-item/forget-user";
import ForgetPassword from "./forget-item/forget-password";
export default {
  data() {
    return {
      showLoginOne: "", //控制显示隐藏
      showLoginTwo: "true", //控制显示隐藏
      loginActive: "ture",
      sginActive: "",
    };
  },
  methods: {
    /**
     * @Author: zhangqinggang
     * @description: 切换显示
     * @param {*}
     * @param2: {params2}
     * @return {*}
     */
    showLogin() {
      this.showLoginOne = false;
      this.showLoginTwo = true;
      this.loginActive = true;
      this.sginActive = "";
    },
    showFromSgin() {
      this.showLoginOne = true;
      this.showLoginTwo = false;
      this.sginActive = true;
      this.loginActive = "";
    },
    /**
     * @Author: zhangqinggang
     * @description: 登录跳转
     * @param {*}
     * @param2: {params2}
     * @return {*}
     */
    goLogin() {
      this.$router.push("/login");
    },
  },
  components: { ForgetUser, ForgetPassword },
};
</script>

<style lang="scss" scope>
@import "./forget.scss";
</style>
